import { React } from "V3";
import { makeStyles } from "@material-ui/styles";
import { styled } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

const MaterialUISwitch = styled(Switch)(({ theme, highlightRed }) => ({
    width: 62,
    height: 24,
    padding: 7,
    pointerEvents: (props) => (props.disabled ? "none" : "auto"),
    opacity: (props) => (props.disabled ? 0.5 : 1),
    "& .MuiSwitch-switchBase": {
        margin: 1,
        padding: 0,
        transform: "translateX(6px)",
        "&.Mui-checked": {
            color: "#fff",
            transform: "translateX(22px)",
            "& .MuiSwitch-thumb": {
                transform: "translate(9px,-1px)",
                backgroundColor: theme.palette.mode === "dark" ? "green" : "#001e3c",
            },
            "& .MuiSwitch-thumb:before": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    "#fff",
                )}" d="M14.89,6.34c-0.28-0.29-0.75-0.29-1.04-0.01c0,0,0,0-0.01,0.01l-5.41,5.41L6.17,9.31c-0.28-0.3-0.75-0.32-1.05-0.04s-0.32,0.75-0.04,1.05l3.32,3.56l6.49-6.48c0.29-0.28,0.3-0.75,0.02-1.04C14.9,6.35,14.9,6.35,14.89,6.34L14.89,6.34z"/></svg>')`,
            },
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        //backgroundColor: theme.palette.mode === "dark" ? "#666" : "#001e3c",
        backgroundColor: highlightRed ? "red" : theme.palette.mode === "dark" ? "#666" : "#001e3c",
        transform: "translate(0px,-1px)",
        width: 24,
        height: 24,
        "&:before": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                "#fff",
            )}" d="M11.06,10l4.71-4.71c0.29-0.29,0.29-0.77,0-1.06s-0.77-0.29-1.06,0L10,8.94L5.29,4.23 c-0.29-0.29-0.77-0.29-1.06,0s-0.29,0.77,0,1.06L8.94,10l-4.71,4.71c-0.29,0.29-0.29,0.77,0,1.06c0.15,0.15,0.34,0.22,0.53,0.22 s0.38-0.07,0.53-0.22L10,11.06l4.71,4.71c0.15,0.15,0.34,0.22,0.53,0.22s0.38-0.07,0.53-0.22c0.29-0.29,0.29-0.77,0-1.06L11.06,10z"/></svg>')`,
        },
    },
    "& .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        borderRadius: 20 / 2,
    },
}));

const useStyles = makeStyles({
    wrapper: ({ paddingLeft }) => ({
        width: "100%",
        height: "100%",
        minHeight: "36px",
        paddingLeft: paddingLeft || "15px",
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
    }),
});

export const V3BooToggle = ({
    name,
    onChange,
    value,
    required = false,
    label,
    error = false,
    size = "small",
    disabled = false,
    highlightRed = false,
    paddingLeft,
    ...props
}) => {
    const classes = useStyles({ error, paddingLeft });

    /**
     * Return
     */
    return (
        <div className={classes.wrapper}>
            <Typography variant="body1" sx={{ display: "inline-block", width: "max-content", marginRight: "10px" }}>
                {label}
                {required ? " *" : ""}
            </Typography>
            <MaterialUISwitch
                disabled={disabled}
                checked={value || false}
                onChange={() => onChange(!value, name)}
                highlightRed={highlightRed}
            />
        </div>
    );
};
